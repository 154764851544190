import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>El Curso</Title>
                <Text>
                    Ús presentem un curs totalment renovat. El curs online de psicotècnics més
                    complet. És un curs llarg on començarem des de 0 i pujarem el nivell fins que
                    estigueu completament preparats per afrontar qualsevol oposició de policia, ja
                    sigui Mossos d'Esquadra, Guàrdia Urbana de Barcelona o Policies Locals. Totes
                    les classes estan penjades en forma de vídeos i exercicis per assignatura per
                    posar-vos a prova. Tots els exercicis estan actualitats i adaptats als exercicis
                    oficials que han sortit en els darrers anys. És per aquest motiu que està pensat
                    i dedicat a tots els opositors, independentment del nivell que tingueu en
                    aquests moments perquè començarem repassant coses bàsiques i anirem pujant el
                    nivell gradualment. És un curs molt viu on setmanalment us anirem penjant
                    material nou. Cada matrícula dura trenta dies que és el temps que calculem que
                    podeu assolir un bon nivell de cara a l'oposició. En cas de necessitar més temps
                    o voler repassar més, teniu la possibilitat de renovar mes a mes. Benvinguts al
                    curs que s'adapta a tu i als teus horaris!
                    <br />
                    <br />
                    <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/h2h-pXRNDp8"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        ></iframe>
                    </div>
                </Text>
            </div>

            {/** Contenido en PDF */}
            <div className="container lg:px-0 py-20">
                <Title>Preview del curs</Title>
                <Text className="text-justify">Contingut del curs en format PDF.</Text>

                <div className="rounded p-5 border shadow-md mt-5">
                    <embed
                        src={require("@static/documents/products/25/presentacion-curso-psicos.pdf")}
                        type="application/pdf"
                        className=""
                        width="100%"
                        height="750px"
                    ></embed>
                </div>
            </div>

            {/** ¿Cómo accedo? */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo accedo?</Title>
                    <Text className="text-justify">
                        Una vez adquirido a través de la sección{" "}
                        <Link to="/tienda/" className="default-link">
                            Tienda
                        </Link>{" "}
                        recibirás un correo con tus credenciales para poder acceder al curso. En el
                        menú superior encontrarás la opción{" "}
                        <a
                            href="https://cursos.iopos.es"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-link"
                        >
                            Plataforma de cursos
                        </a>{" "}
                        que te dirigirá hacia la plataforma donde están almacenados todos los cursos
                        y formaciones.
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
